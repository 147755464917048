<template>
	<div class="courseList">
		<div class="crumbs">
			<span class="current">当前位置：</span>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><span class="cu" @click="toHome">首页</span></el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/courseList' }">课程列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="optionList">
			<div class="optionItem">
				<span class="option_Tit">分类：</span>
				<span :class="{active:typeAll}" @click="choseType('all')">全部</span>
				<span v-for="(item,index) in couretypeList" :key="index" @click="choseType(item)" :class="{active: typeChose == item.dic_id}">{{item.dic_name}}</span>
			</div>
			<div class="optionItem industry" :style="{'height':isOpen ? 'auto':'55px'}">
				<div ref="industryList">
					<span class="option_Tit">行业：</span>
					<span :class="{active:industryAll}" @click="choseIndustry('all')">全部</span>
					<span v-for="(item,index) in industryList" :key="index" @click="choseIndustry(item)" :class="{active: industryChose == item.dic_id}">{{item.dic_name}}</span>
				</div>
				<p class="moreIndustry" @click="openOrHide" v-if="moreShow">更多<i :class="isOpen?'el-icon-arrow-up':'el-icon-arrow-down'"></i></p>
			</div>
			<!-- <div class="optionItem">
				<span class="option_Tit">年度：</span>
				<span :class="{active:yearAll}" @click="choseYearFun('all')">全部</span>
				<span v-for="(item,index) in yearList" :key="index" @click="choseYearFun(item)" :class="{active: yearChose == item}">{{item}}</span>
				<el-date-picker
				    v-model="choseYear"
				    type="year"
				    placeholder="选择年度"
					:picker-options="pickerOptions"
					@change="pickerYear"
					value-format="yyyy">
				</el-date-picker>
			</div> -->
		</div>
		<div v-if="total">
			<div class="course_List">
				<div class="courseItem" v-for="(item,index) in courseList" :key="index" @click="toCourseInfo(item)">
					<img :src="item.cou_thumb" />
					<div class="courseCon">
						<div class="courseTit">{{item.cou_name}}</div>
						<div class="courseTY">
							<span class="courseType" :title="item.cou_industry_name">{{item.cou_industry_name}}</span>
							<span class="courseYear">{{item.cou_year}}年{{item.course_type.dic_name}}</span>
						</div>
						<div class="courseBottom">
							{{item.cou_sumtime}}学时
							<span v-if="item.isBuy" class="courseSign" @click.stop="gotoVideoPlay(item)">立即学习</span>
							<p v-else class="coursePrice">￥<span>{{item.cou_price}}</span></p>
						</div>
					</div>
				</div>
			</div>
			<div class="coursePaginat" v-if="total > pageSize">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :total="total"
				  :page-size="pageSize"
				  @current-change="changPage">
				</el-pagination>
			</div>
		</div>
		<div v-else class="noData">
			<img src="@/views/images/noData.png" />
			<p>暂无数据</p>
		</div>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js'
	export default {
		data() {
			return {
				choseYear:"",//年度
				isOpen:false,//是否展开更多行业
				courseList:[],//课程列表
				couretypeList:[],//分类列表
				industryList:[],//行业列表
				moreShow:false,//是否显示更多
				//控制时间选择范围
				pickerOptions:{
					disabledDate(time) {
						return time.getFullYear() > (new Date().getFullYear() - 5);
					},
				},
				yearList:[],//年份列表
				typeAll:true,//分类全部
				typeChose:"",//分类选中
				industryAll:true,//行业全部
				industryChose:"",//行业选中
				yearAll:true,//年份全部
				yearChose:"",//年份选中
				cou_year:"",//年份
				total:0,//总数
				pageSize:10,//每页多少条
			}
		},
		methods: {
			//点击展开收起
			openOrHide(){
				this.isOpen = !this.isOpen;
			},
			//跳转详情
			toCourseInfo(item){
				this.$router.push({
					path: "/courseInfo",
					query: {
						course_id:this.PublicJs.Encrypt(item.cou_id)
					}
				});
			},
			//获取分类和行业
			getTypeAndIndustry(dic_type){
				var that = this;
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/dictionary.html",{
					page:1,
					limit:999,
					dic_type:dic_type
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						if(dic_type == "industry"){
							this.industryList = response.data.data.data;
							this.$nextTick(() => {
								if(that.$refs.industryList.offsetHeight > 55){
									that.moreShow = true;
								}
							});
						}else{
							this.couretypeList = response.data.data.data;
						}
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//年份
			yearShow(){
				var years= new Date().getFullYear();
				for(var i= 0; i < 5; i++){
					this.yearList.push(years - i)
				}
			},
			//获取课程列表
			getCourseList(page){
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/courseList.html",{
					page:page,
					limit:this.pageSize,
					cou_name:"",
					cou_typeid:this.typeChose,
					cou_industry_id:this.industryChose,
					cou_year:this.yearChose,
					token:window.sessionStorage.getItem('token'),
					are_code:this.$cookies.get("are_code")
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.total = response.data.data.total;
						this.courseList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//选择分类
			choseType(item){
				if(typeof item == "string"){
					this.typeAll = true;
					this.typeChose = "";
				}else{
					this.typeAll = false;
					this.typeChose = item.dic_id;
				}
				//获取课程列表
				this.getCourseList(1)
			},
			//选择行业
			choseIndustry(item){
				if(typeof item == "string"){
					this.industryAll = true;
					this.industryChose = "";
				}else{
					this.industryAll = false;
					this.industryChose = item.dic_id;
				}
				//获取课程列表
				this.getCourseList(1)
			},
			//选择年份
			choseYearFun(item){
				this.choseYear = "";
				if(item == "all"){
					this.yearAll = true;
					this.yearChose = "";
				}else{
					this.yearAll = false;
					this.yearChose = item;
				}
				//获取课程列表
				this.getCourseList(1)
			},
			pickerYear(val){
				if(val){
					this.yearAll = false;
					this.yearChose = val;
				}else{
					this.yearAll = true;
					this.yearChose = "";
				}
				//获取课程列表
				this.getCourseList(1)
			},
			//分页
			changPage(val){
				//获取课程列表
				this.getCourseList(val)
			},
			//跳转首页
			toHome(){
				this.$router.push({
					path: "/"
				});
				Bus.$emit('busIsHome', true);//是否为省平台
				Bus.$emit('busmenuChose', "");
				window.sessionStorage.setItem('isHome',true)
				window.sessionStorage.setItem('menuChose',"")
			},
			//立即学习
			gotoVideoPlay(item){
				this.$router.push({
					path: "/videoPlay",
					query: {
						course_id:this.PublicJs.Encrypt(item.cou_id),
						source:this.PublicJs.Encrypt("courseList")
					}
				});
			}
		},
		created() {
			//获取分类
			this.getTypeAndIndustry("couretype")
			//获取行业
			this.getTypeAndIndustry("industry")
			//年份
			this.yearShow();
			//获取课程列表
			this.getCourseList(1)
		}
	}
</script>

<style scoped="scoped">
	.courseList{
		width: 1200px;
		margin: 0 auto;
		padding-top: 36px;
		padding-bottom: 64px;
	}
	.crumbs {
	    font-size: 14px;
	}
	.cu{
		cursor: pointer;
	}
	.current {
	    vertical-align: top;
	    line-height: 1;
	}
	.el-breadcrumb {
	    display: inline-block;
	    vertical-align: top;
	}
	.optionList{
		width: 100%;
		height: auto;
		background-color: #FFF;
		box-shadow: 0px 5px 7px 0px rgba(179,179,179,0.17); 
		padding: 0 30px;
		margin-top: 29px;
	}
	.optionItem{
		height: 55px;
		line-height: 55px;
		border-bottom: 1px solid #EEEEEE;
		font-size: 14px;
		overflow: hidden;
		position: relative;
	}
	.optionItem:last-child{
		border: none;
	}
	.optionItem span{
		margin-right: 36px;
		color: #333;
		padding: 0 9px;
		display: inline-block;
		height: 26px;
		line-height: 26px;
		border-radius: 13px;
		cursor: pointer;
	}
	.optionItem span.active{
		background-color: #FF4936;
		color: #FFF;
	}
	.optionItem span.option_Tit{
		color: #999;
		padding: 0;
		margin-right: 20px;
	}
	.optionItem.industry{
		padding-right: 66px;
	}
	.moreIndustry{
		display: inline-block;
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
	}
	.moreIndustry i{
		margin-left: 5px;
	}
	.course_List{
		margin-top: 30px;
	}
	.course_List::after,
	.courseTY::after{
		content: "";
		display: block;
		clear: both;
	}
	.courseItem{
		width: 225px;
		height: 236px;
		background-color: #FFF;
		box-shadow: 0px 1px 6px 0px rgba(153,153,153,0.18); 
		float: left;
		margin-right: 18px;
		margin-bottom: 30px;
		cursor: pointer;
	}
	.courseItem:nth-child(5n+5){
	    margin-right:0;
	}
	.courseItem img{
		width: 100%;
		height: 135px;
		display: block;
	}
	.courseCon{
		padding: 0 14px;
	}
	.courseTit{
		white-space:nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 14px;
		color: #333;
		line-height: 34px;
	}
	.courseTY{
		font-size: 12px;
		color: #999;
	}
	.courseType{
		width: 100px;
		white-space:nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
	}
	.courseYear{
		float: right;
	}
	.courseBottom{
		border-top: 1px solid #F1F1F1;
		margin-top: 10px;
		position: relative;
		font-size: 14px;
		color: #333;
		line-height: 40px;
	}
	.coursePrice{
		position: absolute;
		top: 0;
		right: 0;
		color: #ff483b;
		font-size: 16px;
	}
	.coursePrice span{
		font-size: 24px;
	}
	.courseSign{
		display: inline-block;
		width: 60px;
		height: 24px;
		background: linear-gradient(0deg,rgba(77,110,242,0.90) 0%, rgba(77,110,242,0.70) 100%);
		border-radius: 12px;
		text-align: center;
		line-height: 24px;
		color: #FFF;
		font-size: 12px;
		position: absolute;
		right: 0;
		top: 8px;
		cursor: pointer;
	}
	.courseSign:hover{
		background: linear-gradient(0deg, rgba(77,110,242,0.70) 100%,rgba(77,110,242,0.90) 0%);
	}
	
	.coursePaginat{
		text-align: center;
		margin-top: 58px;
	}
	.noData{
		text-align: center;
		margin-top: 50px;
	}
	.noData p{
		padding: 10px 0;
		font-size: 14px;
	}
</style>

<style>
	.coursePaginat .el-pagination.is-background .btn-next, 
	.coursePaginat .el-pagination.is-background .btn-prev, 
	.coursePaginat .el-pagination.is-background .el-pager li{
		background-color: #FFF;
	}
</style>
